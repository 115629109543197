import React, { useState, useEffect } from "react";

const ManageCloudeService = () => {
  return (
    <div>
      <h2 style={{ textAlign: "center",paddingBlock:"10%",marginBottom:"20%" }}>
        "We'll be adding the service soon. Stay tuned!"
      </h2>
    </div>
  );
};

export default ManageCloudeService;
